$(function () {

    /**
     * Animations des logos
     *
     * Home & Recruteur
     */
    var logo = {
        1: ['logo_1a', 'logo_2a'],
        2: ['logo_1b', 'logo_2b'],
        3: ['logo_1c', 'logo_2c'],
        4: ['logo_1d', 'logo_2d'],
        5: ['logo_1e', 'logo_2e']
    };

    var animLogo = setInterval(animationLogo, 3000);


    var logoRandom = Math.floor(Math.random() * Math.floor(5)) + 1;

    function animationLogo() {
        /**
         * Hide logo
         */
        var myArray = [];
        for (var i = 1; i <= $('#'+logo[logoRandom][0]+' .customer__object').length; i++) {
            myArray.push(i);
        }

        var hide = setInterval(hideLogo, 50);

        function hideLogo() {
            var rand = myArray[Math.floor(Math.random()*myArray.length)];
            var index = myArray.indexOf(rand);

            myArray.splice(index, 1);

            $('#'+logo[logoRandom][0]+' .customer__object:nth-child('+rand+')').fadeOut(200);

            if(myArray.length == 0) { stopHideLogo(); }
        }

        function stopHideLogo() {
            $('#'+logo[logoRandom][0]).hide();
            clearInterval(hide);

            /**
             * Show logo
             */
            $('#'+logo[logoRandom][1]).show();
            var myArrayShow = [];
            for (var i = 1; i <= $('#'+logo[logoRandom][1]+' .customer__object').length; i++) {
                myArrayShow.push(i);
            }

            var show = setInterval(showLogo, 50);

            function showLogo() {
                var rand = myArrayShow[Math.floor(Math.random()*myArrayShow.length)];
                var index = myArrayShow.indexOf(rand);

                myArrayShow.splice(index, 1);

                $('#'+logo[logoRandom][1]+' .customer__object:nth-child('+rand+')').fadeIn(200);

                if(myArrayShow.length == 0) { stopShowLogo(); }
            }

            function stopShowLogo() {
                logo[logoRandom].reverse();
                clearInterval(show);
            }
        }

        logoRandom = Math.floor(Math.random() * Math.floor(5)) + 1;
    }

});
